import { useControls, folder, button, Leva } from "leva";
import useStore from "./store";
import info_icon from "../assets/info_sign.svg";
import flag_pt from "../assets/PT.png";
import flag_en from "../assets/EN.png";
import "../index.css";

const TEXTS_PT = [
  { name: "POLITICA de PRIVACIDADE", },
  { name: "  Este site não recolhe dados pessoais ou informação de qualquer outra natureza." },
  { name: "  Como tal, não processa ou divulga dados e informações de forma alguma." },
  { name: "POLITICA de COOKIES" },
  { name: "  Este site não usa cookies, apenas utiliza a memória de browser para manter as preferências do jogo." },
  { name: "ISENÇÃO DE RESPONSABILIDADE" },
  { name: "  Este jogo foi desenvolvido apenas com fins didáticos e sem qualquer intuito comercial." },
  { name: "PONTO DE PARTIDA" },
  { name: "Este jogo teve como ponto de partida o seguinte projeto:" },
  { name: "TOP LINKS" },
];

const TEXTS_EN = [
  { name: "PRIVACY POLICY" },
  { name: "  This site does not collect personal data or information of any other nature." },
  { name: "  As such, it does not process or disclose data and information in any way." },
  { name: "COOKIES POLICY" },
  { name: "  This site does not use cookies, only uses browser memory to maintain game preferences." },
  { name: "DISCLAIMER" },
  { name: "  This game was developed for educational purposes only and without any commercial intent." },
  { name: "STARTING POINT" },
  { name: "This game had the following project as its starting point:" },
  { name: "TOP LINKS" },
];

const TLINKS = [
  { name: "RESOLUTION", url: "https://resolution.pt/" },
  { name: "REACT", url: "https://react.dev/" },
  { name: "THREEJS", url: "https://threejs.org/" },
  { name: "CANNON", url: "https://github.com/pmndrs/use-cannon" },
  { name: "DREI", url: "https://github.com/pmndrs/drei" },
  { name: "FIBER", url: "https://docs.pmnd.rs/react-three-fiber/getting-started/introduction" },
  { name: "RAPIER", url: "https://github.com/pmndrs/react-three-rapier" },
  { name: "LEVA", url: "https://github.com/pmndrs/leva" },
  { name: "ZUSTAND", url: "https://github.com/pmndrs/zustand" },
  { name: "REACT-DEVICE-DETECT", url: "https://www.npmjs.com/package/react-device-detect" },
  { name: "GLTF", url: "https://sbcode.net/react-three-fiber/use-gltf/" },
  { name: "GLTFJSX", url: "https://sbcode.net/react-three-fiber/gltfjsx/" },
  { name: "EDITOR.SBCODE.NET", url: "https://editor.sbcode.net/035deb31bca29a66ab05fb11cc0eb06cfef64c58" },
  { name: "EDITOR.SBCODE.NET", url: "https://editor.sbcode.net/035deb31bca29a66ab05fb11cc0eb06cfef64c58" },
];

const PROJ = [
  { name: "r3f-playroom-multiplayer-shooter-game", url: "https://github.com/wass08/r3f-playroom-multiplayer-shooter-game" },
 ];

{
  /* <Model url={Models[Models.findIndex((m) => m.title === title)].url} /> */
}

export const Info = () => {

  const buttonClick = (link) => {
    window.location.href = link;
    {
      /* <a
  href={link}
  target="_blank"
  rel="noreferrer"
  // title="simple three.js game"
></a>; */
    }
  };

  const infor = useControls(
    "Informação",
    {
      1: { value: TEXTS_PT[0].name },
      2: { value: TEXTS_PT[1].name },
      3: { value: TEXTS_PT[2].name },
      4: { value: TEXTS_PT[3].name },
      5: { value: TEXTS_PT[4].name },
      6: { value: TEXTS_PT[5].name },
      7: { value: TEXTS_PT[6].name },
      8: { value: TEXTS_PT[7].name },
      9: { value: TEXTS_PT[8].name },
      "r3f-playroom-multiplayer-shooter-game": button(() =>
        buttonClick(PROJ[0].url)
      ),
    },
    { collapsed: true }
  );

  const inforEn = useControls(
    "Information",
    {
      1: { value: TEXTS_EN[0].name },
      2: { value: TEXTS_EN[1].name },
      3: { value: TEXTS_EN[2].name },
      4: { value: TEXTS_EN[3].name },
      5: { value: TEXTS_EN[4].name },
      6: { value: TEXTS_EN[5].name },
      7: { value: TEXTS_EN[6].name },
      8: { value: TEXTS_EN[7].name },
      9: { value: TEXTS_EN[8].name },
      "r3f-playroom-multiplayer-shooter-game": button(() =>
        buttonClick(PROJ[0].url)
      ),
    },
    { collapsed: true }
  );
  // The library for web and native user interfaces

  const links = useControls(
    "Links",
    {
      "* RESOLUTION - Consultores Informáticos": button(() =>
        buttonClick(TLINKS[0].url)
      ),
      "* REACT": button(() => buttonClick(TLINKS[1].url)),
      "* THREEJS": button(() => buttonClick(TLINKS[2].url)),
      "* CANNON": button(() => buttonClick(TLINKS[3].url)),
      "* DREI": button(() => buttonClick(TLINKS[4].url)),
      "* FIBER": button(() => buttonClick(TLINKS[5].url)),
      "* RAPIER": button(() => buttonClick(TLINKS[6].url)),
      "* LEVA": button(() => buttonClick(TLINKS[7].url)),
      "* ZUSTAND": button(() => buttonClick(TLINKS[8].url)),
      "* REACT-DEVICE-DETECT": button(() => buttonClick(TLINKS[9].url)),
      "* GLTF": button(() => buttonClick(TLINKS[10].url)),
      "* GLTFJSX": button(() => buttonClick(TLINKS[11].url)),
      "* EDITOR SBCODE NET": button(() => buttonClick(TLINKS[12].url)),
    },
    { collapsed: true }
  );

  const info = useStore((s) => s.info);
  const setInfo = useStore((s) => s.setInfo);

  let FLAG = "";
  let lang = "";
  const lsl = localStorage.getItem("pilkolanguage");
  if (lsl !== null && lsl !== "") {
    lang = lsl;
  } else {
    lang = useStore((s) => s.lang);
  }
  const setLang = useStore((s) => s.setLang);

  if (lang === "EN") {
    FLAG = flag_pt;
  } else {
    FLAG = flag_en;
  }

  return (
    <>
      <div>
        <span id="leva__root">
          <Leva />
        </span>
        <button
          className="fixed top-4 right-4 z-10 text-white"
          onClick={() => {
            setInfo(!info);
          }}
        >
          <img
            alt="logo for the game"
            className="logo "
            src={info_icon}
            width={25}
          />
        </button>

      </div>
        <button
          className="fixed top-4 right-14 z-10 text-white"
          onClick={() => {
            {
              lang === "PT" ? (lang = "EN") : (lang = "PT");
            }
            setLang(lang);
            localStorage.setItem("pilkolanguage", lang);
            window.location.reload(false);
          }}
        >
          <img
            alt="language"
            className="logo "
            src={FLAG}
            width={25}
          />
        </button>
    </>
  );
};

{
  /* <Leva
  theme={myTheme} // you can pass a custom theme (see the styling section)
  fill // default = false,  true makes the pane fill the parent dom node it's rendered in
  flat // default = false,  true removes border radius and shadow
  oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
  hideTitleBar // default = false, hides the GUI header
  collapsed // default = false, when true the GUI is collpased
  hidden // default = false, when true the GUI is hidden
/>     */
}
