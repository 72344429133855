import {
  Box,
  // ContactShadows,
  // Environment,
  // OrbitControls,
  Text,
  Sphere,
} from "@react-three/drei";
import {
  CuboidCollider,
  // Physics,
  RigidBody,
  // RigidBodyProps,
} from "@react-three/rapier";
// import { useFrame } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { MeshPhysicalMaterial } from "three";
import useStore from "./store";

const material = new MeshPhysicalMaterial();

const BALL_SPEED = 15;

const text_pt = "Golo";
const text_en = "Goal";

let goal_txt = "";

export const GoalsAndBall = ({players, angle}) => {

  const grp = useRef();
  const ref = useRef();
  const rb_ball = useRef(null);
  const rb_goal1 = useRef(null);
  const rb_goal2 = useRef(null);

  const [intersecting, setIntersection] = useState(false);
  const [intersecting2, setIntersection2] = useState(false);

  const isGoal = () => {
    players[0].state.state.deaths += 1;
    players[1].state.state.kills += 1;
  };

  const isGoal2 = () => {
    players[0].state.state.kills += 1;
    players[1].state.state.deaths += 1;
  };

  let lang = useStore((s) => s.lang);
  const lsl = localStorage.getItem("pilkolanguage");
  if (lsl !== null && lsl !== "") {
    lang = lsl;
  } else {
    lang = useStore((s) => s.lang);
  }

  if (lang === "EN") {
    goal_txt = text_en;
  } else {
    goal_txt = text_pt;
  }

  return (
    <group ref={grp}>
      {/* Ball  */}
      <group ref={ref}>
        <RigidBody
          // colliders="ball"
          name="ball"
          position={[0, 19, 0]}
          linearVelocity={[0, 0, 0]}
          // restitution={1}
          scale={0.3}
          ref={rb_ball}
          onIntersectionEnter={(e) => {
            if (e.other.rigidBodyObject.name === "goal1") {
              rb_ball.current?.setTranslation({ x: 0, y: 19, z: 0 });
              rb_ball.current?.setLinvel({ x: 0, y: 0, z: 0 });
              const audio = new Audio("/audios/goal.mp3");
              audio.volume = 0.5;
              audio.play();
            } else if (e.other.rigidBodyObject.name === "goal2") {
              rb_ball.current?.setTranslation({ x: 0, y: 19, z: 0 });
              rb_ball.current?.setLinvel({ x: 0, y: 0, z: 0 });
              const audio = new Audio("/audios/goal.mp3");
              audio.volume = 0.5;
              audio.play();
            } else {
              // rb_ball.current.applyImpulse({ x: 0, y: 0, z: -5 });
              const impulse = {
                x: Math.sin(angle) * BALL_SPEED,
                y: 0,
                z: Math.cos(angle) * BALL_SPEED,
              };
              rb_ball.current.setLinvel(impulse, true);
            }
          }}
          // onIntersectionExit={() => setIntersection(false)}
          onClick={(ev) => {
            ev.nativeEvent.preventDefault();
            rb_ball.current?.setTranslation({ x: 0, y: 19, z: 0 });
            rb_ball.current?.setLinvel({ x: 0, y: 0, z: 0 });
          }}
        >
          <Sphere material={material} />
        </RigidBody>
      </group>

      {/* Goal1  */}
      <RigidBody
        name="goal1"
        position={[0, 2, 18.9]}
        scale={0.5}
        ref={rb_goal1}
        // colliders={false}
        // linearDamping={12}
        lockRotations
        lockTranslations
        onClick={(ev) => {
          ev.nativeEvent.preventDefault();
          rb_goal1.current?.setTranslation({ x: 0, y: 5, z: 19 });
          rb_goal1.current?.setLinvel({ x: 0, y: 0, z: 0 });
        }}
      >
        <Box
          scale={[11, 1, 1]}
          position={[0, 3, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 6, 1]}
          position={[-5, 0, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 6, 1]}
          position={[5, 0, 0]}
          material={material}
          castShadow
        />

        <Box
          scale={[1, 1, 3]}
          position={[-5, -3, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 1, 3]}
          position={[5, -3, 0]}
          material={material}
          castShadow
        />

        <Suspense fallback={null}>
          {intersecting && (
            <Text color="red" position={[0, 0, 1]} fontSize={2}>
              {goal_txt}
            </Text>
          )}
        </Suspense>

        {/**
         * We create a collider and set it to be a 'sensor'
         * This enabled intersection events, and enables
         * colliders to pass through it.
         */}
        <CuboidCollider
          position={[0, 0, 1]}
          args={[5, 3, 1]}
          sensor
          onIntersectionEnter={() => {
            setIntersection(true);
            isGoal();
            // setSeconds(60);
          }}
          onIntersectionExit={() => setIntersection(false)}
        />
      </RigidBody>

      {/* Goal2  */}

      <RigidBody
        name="goal2"
        position={[0, 2, -18.9]}
        scale={0.5}
        ref={rb_goal2}
        lockRotations
        lockTranslations
        onClick={(ev) => {
          ev.nativeEvent.preventDefault();
          rb_goal2.current?.setTranslation({ x: 0, y: 5, z: -19 });
          rb_goal2.current?.setLinvel({ x: 0, y: 0, z: 0 });
        }}
      >
        <Box
          scale={[11, 1, 1]}
          position={[0, 3, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 6, 1]}
          position={[-5, 0, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 6, 1]}
          position={[5, 0, 0]}
          material={material}
          castShadow
        />

        <Box
          scale={[1, 1, 3]}
          position={[-5, -3, 0]}
          material={material}
          castShadow
        />
        <Box
          scale={[1, 1, 3]}
          position={[5, -3, 0]}
          material={material}
          castShadow
        />

        <Suspense fallback={null}>
          {intersecting2 && (
            <Text color="blue" position={[0, 0, 1]} fontSize={2}>
              {goal_txt}
            </Text>
          )}
        </Suspense>

        {/**
         * We create a collider and set it to be a 'sensor'
         * This enabled intersection events, and enables
         * colliders to pass through it.
         */}
        <CuboidCollider
          position={[0, 0, 1]}
          args={[5, 3, 1]}
          sensor
          onIntersectionEnter={() => {
            setIntersection2(true);
            isGoal2();
            // setSeconds(120);
          }}
          // (setIntersection2(true), isGoal2())}
          onIntersectionExit={() => setIntersection2(false)}
        />
      </RigidBody>
    </group>
  );
};
