import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useEffect, useState } from "react";
import { useMultiplayerState } from "playroomkit";

const field = "models/field.glb";
const cone = "models/coneGreen.glb";
const cone1 = "models/coneGreen1.glb";
const cone2 = "models/coneGreen2.glb";
const cone3 = "models/coneGreen3.glb";
const cone4 = "models/coneGreen4.glb";
const cone5 = "models/coneGreen5.glb";
const cone6 = "models/coneGreen6.glb";
const cone7 = "models/coneGreen7.glb";
const cone8 = "models/coneGreen8.glb";
const cone9 = "models/coneGreen9.glb";
const cone10 = "models/coneRed.glb";
const cone11 = "models/coneRed1.glb";
const cone12 = "models/coneRed2.glb";
const cone13 = "models/coneRed3.glb";
const cone14 = "models/coneRed4.glb";
const cone15 = "models/coneRed5.glb";
const cone16 = "models/coneRed6.glb";
const cone17 = "models/coneRed7.glb";
const cone18 = "models/coneRed8.glb";
const cone19 = "models/coneRed9.glb";
const cone20 = "models/coneBlue.glb";
const cone21 = "models/coneBlue1.glb";
const cone22 = "models/coneBlue2.glb";
const cone23 = "models/coneBlue3.glb";
const cone24 = "models/coneBlue4.glb";
const cone25 = "models/coneBlue5.glb";
const cone26 = "models/coneBlue6.glb";
const cone27 = "models/coneBlue7.glb";
const cone28 = "models/coneBlue8.glb";
const cone29 = "models/coneBlue9.glb";
const cone30 = "models/coneYellow.glb";
const cone31 = "models/coneYellow1.glb";
const cone32 = "models/coneYellow2.glb";
const cone33 = "models/coneYellow3.glb";
const cone34 = "models/coneYellow4.glb";
const cone35 = "models/coneYellow5.glb";
const cone36 = "models/coneYellow6.glb";
const cone37 = "models/coneYellow7.glb";
const cone38 = "models/coneYellow8.glb";
const cone39 = "models/coneYellow9.glb";

export const Map = () => {
  // const map = useGLTF("models/map.glb");
  const map = useGLTF(field);
  const trafic = useGLTF(cone);
  const trafic1 = useGLTF(cone1);
  const trafic2 = useGLTF(cone2);
  const trafic3 = useGLTF(cone3);
  const trafic4 = useGLTF(cone4);
  const trafic5 = useGLTF(cone5);
  const trafic6 = useGLTF(cone6);
  const trafic7 = useGLTF(cone7);
  const trafic8 = useGLTF(cone8);
  const trafic9 = useGLTF(cone9);
  const trafic10 = useGLTF(cone10);
  const trafic11 = useGLTF(cone11);
  const trafic12 = useGLTF(cone12);
  const trafic13 = useGLTF(cone13);
  const trafic14 = useGLTF(cone14);
  const trafic15 = useGLTF(cone15);
  const trafic16 = useGLTF(cone16);
  const trafic17 = useGLTF(cone17);
  const trafic18 = useGLTF(cone18);
  const trafic19 = useGLTF(cone19);
  const trafic20 = useGLTF(cone20);
  const trafic21 = useGLTF(cone21);
  const trafic22 = useGLTF(cone22);
  const trafic23 = useGLTF(cone23);
  const trafic24 = useGLTF(cone24);
  const trafic25 = useGLTF(cone25);
  const trafic26 = useGLTF(cone26);
  const trafic27 = useGLTF(cone27);
  const trafic28 = useGLTF(cone28);
  const trafic29 = useGLTF(cone29);
  const trafic30 = useGLTF(cone30);
  const trafic31 = useGLTF(cone31);
  const trafic32 = useGLTF(cone32);
  const trafic33 = useGLTF(cone33);
  const trafic34 = useGLTF(cone34);
  const trafic35 = useGLTF(cone35);
  const trafic36 = useGLTF(cone36);
  const trafic37 = useGLTF(cone37);
  const trafic38 = useGLTF(cone38);
  const trafic39 = useGLTF(cone39);

  let cones = [];

  const [load, setLoad] = useMultiplayerState('load', false);
  const [randomCones, setRandomCones] = useMultiplayerState('cones', []);

  const RandomCones = () => {
    if (load === false) {
      setLoad(true);
      let xx = 0;
      let zz = 0;
      let ww = 0;
      let i = 0;
      for (let i = 0; i < 40; i++) {
        ww = [Math.floor(Math.random() * 2)];
        if (ww[0] === 0) {
          xx = [Math.floor(Math.random() * -17)];
        } else {
          xx = [Math.floor(Math.random() * 17)];
        }
        ww = [Math.floor(Math.random() * 2)];
        if (ww[0] === 0) {
          zz = [Math.floor(Math.random() * -22)];
        } else {
          zz = [Math.floor(Math.random() * 14)];
        }
        cones.push([xx[0], zz[0]]);
      }
      setRandomCones(cones);
    } else {
      cones = randomCones;
    }
  };

  const Cones = ({ num } ) => {

    switch (num) {
      case 0:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic.scene}
              position={[cones[0][0], 0, cones[0][1]]}
            />
          </RigidBody>
        );
        break;
      case 1:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic1.scene}
              position={[cones[1][0], 0, cones[1][1]]}
            />
          </RigidBody>
        );
        break;
      case 2:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic2.scene}
              position={[cones[2][0], 0, cones[2][1]]}
            />
          </RigidBody>
        );
        break;
      case 3:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic3.scene}
              position={[cones[3][0], 0, cones[3][1]]}
            />
          </RigidBody>
        );
        break;
      case 4:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic4.scene}
              position={[cones[4][0], 0, cones[4][1]]}
            />
          </RigidBody>
        );
        break;
      case 5:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic5.scene}
              position={[cones[5][0], 0, cones[5][1]]}
            />
          </RigidBody>
        );
        break;
      case 6:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic6.scene}
              position={[cones[6][0], 0, cones[6][1]]}
            />
          </RigidBody>
        );
        break;
      case 7:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic7.scene}
              position={[cones[7][0], 0, cones[7][1]]}
            />
          </RigidBody>
        );
        break;
      case 8:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic8.scene}
              position={[cones[8][0], 0, cones[8][1]]}
            />
          </RigidBody>
        );
        break;
      case 9:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic9.scene}
              position={[cones[9][0], 0, cones[9][1]]}
            />
          </RigidBody>
        );
        break;
      case 10:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic10.scene}
              position={[cones[10][0], 0, cones[10][1]]}
            />
          </RigidBody>
        );
        break;
      case 11:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic11.scene}
              position={[cones[11][0], 0, cones[11][1]]}
            />
          </RigidBody>
        );
        break;
      case 12:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic12.scene}
              position={[cones[12][0], 0, cones[12][1]]}
            />
          </RigidBody>
        );
        break;
      case 13:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic13.scene}
              position={[cones[13][0], 0, cones[13][1]]}
            />
          </RigidBody>
        );
        break;
      case 14:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic14.scene}
              position={[cones[14][0], 0, cones[14][1]]}
            />
          </RigidBody>
        );
        break;
      case 15:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic15.scene}
              position={[cones[15][0], 0, cones[15][1]]}
            />
          </RigidBody>
        );
        break;
      case 16:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic16.scene}
              position={[cones[16][0], 0, cones[16][1]]}
            />
          </RigidBody>
        );
        break;
      case 17:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic17.scene}
              position={[cones[17][0], 0, cones[17][1]]}
            />
          </RigidBody>
        );
        break;
      case 18:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic18.scene}
              position={[cones[18][0], 0, cones[18][1]]}
            />
          </RigidBody>
        );
        break;
      case 19:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic19.scene}
              position={[cones[19][0], 0, cones[19][1]]}
            />
          </RigidBody>
        );
        break;
      case 20:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic20.scene}
              position={[cones[20][0], 0, cones[20][1]]}
            />
          </RigidBody>
        );
        break;
      case 21:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic21.scene}
              position={[cones[21][0], 0, cones[21][1]]}
            />
          </RigidBody>
        );
        break;
      case 22:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic22.scene}
              position={[cones[22][0], 0, cones[22][1]]}
            />
          </RigidBody>
        );
        break;
      case 23:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic23.scene}
              position={[cones[23][0], 0, cones[23][1]]}
            />
          </RigidBody>
        );
        break;
      case 24:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic24.scene}
              position={[cones[24][0], 0, cones[24][1]]}
            />
          </RigidBody>
        );
        break;
      case 25:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic25.scene}
              position={[cones[25][0], 0, cones[25][1]]}
            />
          </RigidBody>
        );
        break;
      case 26:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic26.scene}
              position={[cones[26][0], 0, cones[26][1]]}
            />
          </RigidBody>
        );
        break;
      case 27:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic27.scene}
              position={[cones[27][0], 0, cones[27][1]]}
            />
          </RigidBody>
        );
        break;
      case 28:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic28.scene}
              position={[cones[28][0], 0, cones[28][1]]}
            />
          </RigidBody>
        );
        break;
      case 29:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic29.scene}
              position={[cones[29][0], 0, cones[29][1]]}
            />
          </RigidBody>
        );
        break;
      case 30:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic30.scene}
              position={[cones[30][0], 0, cones[30][1]]}
            />
          </RigidBody>
        );
        break;
      case 31:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic31.scene}
              position={[cones[31][0], 0, cones[31][1]]}
            />
          </RigidBody>
        );
        break;
      case 32:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic32.scene}
              position={[cones[32][0], 0, cones[32][1]]}
            />
          </RigidBody>
        );
        break;
      case 33:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic33.scene}
              position={[cones[33][0], 0, cones[33][1]]}
            />
          </RigidBody>
        );
        break;
      case 34:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic34.scene}
              position={[cones[34][0], 0, cones[34][1]]}
            />
          </RigidBody>
        );
        break;
      case 35:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic35.scene}
              position={[cones[35][0], 0, cones[35][1]]}
            />
          </RigidBody>
        );
        break;
      case 36:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic36.scene}
              position={[cones[36][0], 0, cones[36][1]]}
            />
          </RigidBody>
        );
        break;
      case 37:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic37.scene}
              position={[cones[37][0], 0, cones[37][1]]}
            />
          </RigidBody>
        );
        break;
      case 38:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic38.scene}
              position={[cones[38][0], 0, cones[38][1]]}
            />
          </RigidBody>
        );
        break;
      case 39:
        return (
          <RigidBody type="fixed">
            <primitive
              object={trafic39.scene}
              position={[cones[39][0], 0, cones[39][1]]}
            />
          </RigidBody>
        );
        break;
      default:
    }
    //  };
  };

  const TraficCones = () => {
    return (
      <>
        <Cones num={0} />
        <Cones num={1} />
        <Cones num={2} />
        <Cones num={3} />
        <Cones num={4} />
        <Cones num={5} />
        <Cones num={6} />
        <Cones num={7} />
        <Cones num={8} />
        <Cones num={9} />
        <Cones num={10} />
        <Cones num={11} />
        <Cones num={12} />
        <Cones num={13} />
        <Cones num={14} />
        <Cones num={15} />
        <Cones num={16} />
        <Cones num={17} />
        <Cones num={18} />
        <Cones num={19} />
        <Cones num={20} />
        <Cones num={21} />
        <Cones num={22} />
        <Cones num={23} />
        <Cones num={24} />
        <Cones num={25} />
        <Cones num={26} />
        <Cones num={27} />
        <Cones num={28} />
        <Cones num={29} />
        <Cones num={30} />
        <Cones num={31} />
        <Cones num={32} />
        <Cones num={33} />
        <Cones num={34} />
        <Cones num={35} />
        <Cones num={36} />
        <Cones num={37} />
        <Cones num={38} />
        <Cones num={39} />
      </>
    );
  };

  useEffect(() => {
    map.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  });

  return (
    <>
      <RigidBody colliders="trimesh" type="fixed">
        <primitive object={map.scene} />
      </RigidBody>
      <RandomCones />
      <TraficCones />
    </>
  );
};
// useGLTF.preload("models/map.glb");
useGLTF.preload(field);
