import { Environment } from "@react-three/drei";
import {
  Joystick,
  insertCoin,
  isHost,
  myPlayer,
  onPlayerJoin,
  useMultiplayerState,
  // usePlayersState,
} from "playroomkit";
import { useEffect, useState } from "react";
import { Bullet } from "./Bullet";
import { BulletHit } from "./BulletHit";
import { CharacterController } from "./CharacterController";
import { Map } from "./Map";
import useStore from "./store";
import * as rdd from "react-device-detect";

import { GoalsAndBall } from "./GoalsAndBall";

const text_pt = ["chuta"];
const text_en = ["kick"];

let fire_txt = "";

export const Experience = ({ downgradedPerformance = false } ) => {

  const [players, setPlayers] = useState([]);

  const start = async () => {
    // Start the game
    await insertCoin();

    // Create a joystick controller for each joining player
    onPlayerJoin((state) => {
      // Joystick will only create UI for current player (myPlayer)
      // For others, it will only sync their state
      const joystick = new Joystick(state, {
        type: "angular",
        buttons: [{ id: "fire", label: fire_txt }],
      });
      const newPlayer = { state, joystick };
      state.setState("health", 100);
      state.setState("deaths", 0);
      state.setState("kills", 0);
      // state.setState("Posi", posi);
      setPlayers((players) => [...players, newPlayer]);
      state.onQuit(() => {
        setPlayers((players) => players.filter((p) => p.state.id !== state.id));
      });
    });
  };

  useEffect(() => {
    start();
  }, []);

  const [bullets, setBullets] = useState([]);
  const [hits, setHits] = useState([]);
  const [angle, setAngle] = useState(0);

  const [networkBullets, setNetworkBullets] = useMultiplayerState(
    "bullets",
    []
  );
  const [networkHits, setNetworkHits] = useMultiplayerState("hits", []);

  const onFire = (bullet, angle) => {
    setBullets((bullets) => [...bullets, bullet]);
    setAngle(angle);
  };

  const onHit = (bulletId, position) => {
    setBullets((bullets) => bullets.filter((bullet) => bullet.id !== bulletId));
    setHits((hits) => [...hits, { id: bulletId, position }]);
  };

  const onHitEnded = (hitId) => {
    setHits((hits) => hits.filter((h) => h.id !== hitId));
  };

  useEffect(() => {
    setNetworkBullets(bullets);
  }, [bullets]);

  useEffect(() => {
    setNetworkHits(hits);
  }, [hits]);

  const onKilled = (_victim, killer) => {
    const killerState = players.find((p) => p.state.id === killer).state;
    killerState.setState("kills", killerState.state.kills + 1);
  };

  let lang = useStore((s) => s.lang);
  const lsl = localStorage.getItem("pilkolanguage");
  if (lsl !== null && lsl !== "") {
    lang = lsl;
  } else {
    lang = useStore((s) => s.lang);
  }

  if (rdd.isMobile) {
    if (lang === "EN") {
      fire_txt = text_en.slice(0, 1);
    } else {
      fire_txt = text_pt.slice(0, 1);
    }
  } else {
    if (lang === "EN") {
      fire_txt = "&nbsp;&nbsp;" + text_en.slice(0, 1) +  "<br>" + "Ctrl Key";
    } else {
      fire_txt = "&nbsp;&nbsp;" + text_pt.slice(0, 1) +  "<br>" + "Ctrl Key";
    }
  };

  return (
    <>
      <Map />
      {players.map(({ state, joystick }, index) => (
        <CharacterController
          key={state.id}
          state={state}
          userPlayer={state.id === myPlayer()?.id}
          joystick={joystick}
          onKilled={onKilled}
          onFire={onFire}
          downgradedPerformance={downgradedPerformance}
        />
      ))}
      {(isHost() ? bullets : networkBullets).map((bullet) => (
        <Bullet
          key={bullet.id}
          {...bullet}
          onHit={(position) => onHit(bullet.id, position)}
        />
      ))}
      {(isHost() ? hits : networkHits).map((hit) => (
        <BulletHit key={hit.id} {...hit} onEnded={() => onHitEnded(hit.id)} />
      ))}
      <Environment preset="sunset" />
      <GoalsAndBall players={players} angle={angle} />
    </>
  );
};
