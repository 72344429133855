import { Loader, PerformanceMonitor, SoftShadows } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { Physics } from "@react-three/rapier";
import { Suspense, useState } from "react";
import { Experience } from "./components/Experience";
import { Leaderboard } from "./components/Leaderboard";
import { Info } from "./components/Info";
import useStore from "./components/store";

const text_pt = [
  "com extras",
  "com drones",
  "(pronunciar sPÁtsa BaTÁlo)",
  "PLAY",
  "RECOMEÇAR",
  "Fundo escuro",
  "Treinar",
  "Let",
  "Me",
];
const text_en = [
  "with extras",
  "with drones",
  "(pronounce sPAtsa BaTAlo)",
  "PLAY",
  "RESTART",
  "Dark",
  "Training",
  "Enter",
  "24",
];

let auth = "";
const lsa = localStorage.getItem("pilkludo");
if (lsa !== null && lsa !== "") {
  auth = lsa;
}

let acesso =
  text_pt.slice(7, 8) +
  text_pt.slice(8, 9) +
  text_en.slice(7, 8) +
  "@" +
  text_en.slice(8, 9);

function App() {
  const [downgradedPerformance, setDowngradedPerformance] = useState(false);
  if (auth !== acesso) {
    let auth = prompt("Please enter the access sentence");
    if (auth !== acesso) {
      window.alert("This game is not available yet");
      window.location.reload(false);
    } else {
      localStorage.setItem("pilkludo", acesso);
    }
  }

  const info = useStore((s) => s.info);

  return (
    <>
      <Loader />
      {info ? <Info /> : <Leaderboard />}
      <Canvas
        shadows
        camera={{ position: [0, 30, 0], fov: 50, near: 2 }}
        dpr={[1, 1.5]} // optimization to increase performance on retina/4k devices
      >
        <color attach="background" args={["#242424"]} />
        <SoftShadows size={42} />

        <PerformanceMonitor
          // Detect low performance devices
          onDecline={(fps) => {
            setDowngradedPerformance(true);
          }}
        />
        <Suspense>
          <Physics>
            <Experience downgradedPerformance={downgradedPerformance} />
          </Physics>
        </Suspense>
        {/* <OrbitControls /> */}
        {!downgradedPerformance && (
          // disable the postprocessing on low-end devices
          <EffectComposer disableNormalPass>
            <Bloom luminanceThreshold={1} intensity={1.5} mipmapBlur />
          </EffectComposer>
        )}
      </Canvas>
    </>
  );
}

export default App;
