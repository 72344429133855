import { create } from "zustand";
import { persist } from "zustand/middleware";
// import { mountStoreDevtool } from "simple-zustand-devtools";

const useStore = create(
  persist(
    (set, get) => ({
      info: false,
      lang: "PT",
      // load: false,
      // cones: [],
      // setLoad: (load) => set((state) => ({ load })),
      // setCones: (cones) => set((state) => ({ cones })),
      setInfo: (info) => set((state) => ({ info })),
      setLang: (lang) => set((state) => ({ lang })),
    }),
    {
      partialize: (state) => ({
        lang: state.lang,
      }),
    }
  )
);

// mountStoreDevtool("STORE", useStore);

export default useStore;
